<template>
  <div class="col-12 mb-3 text-center small" >
    <div class="bold">
      IMPORTANT - CARD CHARGES APPLY:
    </div>
    <div class="mb-3 mt-1">
      Processing fees, determined by the payment gateway and your card issuer, will be applied. The final charge reflected on your account will be a total of your donation amount + fees.
    </div>
    <div>
      These fees are deducted from the final amount we receive. We operate on a 100% donations policy on the net Sadaqah/Zakaah amounts received.
    </div>
  </div>
</template>

<script>

export default ({
  name: 'StripePaymentText'
})
</script>
